/* eslint-disable import/prefer-default-export */

// eslint-disable-next-line no-underscore-dangle
const checkRecaptcha = (recaptchaRef, email) =>
  __PROD__ &&
  recaptchaRef &&
  recaptchaRef.current &&
  !process.env.CAPTCHA_PASSTHROUGH_EMAILS?.includes(email);

// eslint-disable-next-line no-underscore-dangle
const skipRecaptcha = (displayMFARequest, email) =>
  __DEV__ ||
  __STAGE__ ||
  displayMFARequest ||
  process.env.CAPTCHA_PASSTHROUGH_EMAILS?.includes(email);

export { checkRecaptcha, skipRecaptcha };
