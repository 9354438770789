import markdeep from 'imports-loader?markdeepOptions=>{mode:"script"}!exports-loader?markdeep!markdeep';
import flatMap from "lodash/flatMap";
import unescape from "lodash/unescape";

export { markdeep };

// Prefetch katex module
let katex;
import(/* webpackPrefetch: true */ "katex").then(module => {
  katex = module.default;
  return katex;
});

export function processCleanupHTML(html) {
  let cleanHtml = String(html);
  cleanHtml = cleanHtml.replace(/&amp;/g, "&");
  cleanHtml = cleanHtml.replace(/&gt;/g, ">");
  cleanHtml = cleanHtml.replace(/&lt;/g, "<");
  cleanHtml = cleanHtml.replace(/\$\$/g, "");
  cleanHtml = cleanHtml.replace(
    /[\u0020\u00A0\u1680\u180e\u2000\u2001\u2022\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000]/gi,
    " ",
  );
  return cleanHtml;
}

export function escapeCodeBlocks(html) {
  return html.replace(/<code(\s+[^>]*)?>(.*?)<\/code>/gs, (match, p1, p2) => {
    const replacedContent = p2.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    return `<code${p1 || ""}>${replacedContent}</code>`;
  });
}

export function unescapeCodeBlocks(html) {
  return html.replace(/<code(\s+[^>]*)?>(.*?)<\/code>/gs, (match, p1, p2) => {
    const replacedContent = p2.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    return `<code${p1 || ""}>${replacedContent}</code>`;
  });
}

export function processLinks(html) {
  const TARGET = 'target="_blank"';
  const A_TAG = "<a ";
  let parts = html.split(A_TAG);
  parts = parts.map((value, index) => {
    if (index === 0) return value;

    if (value.includes(TARGET)) return value;
    return `${TARGET} ${value}`;
  });
  return parts.join(A_TAG);
}

export function processImages(html) {
  return html
    .split("<img")
    .join(
      "<img onerror='const src = this.src; const retries=parseInt(this.dataset.retries) || 1; if (retries < 3) { this.src = src; this.dataset.retries = retries + 1; }'",
    );
}

export function processWithMarkDeep(html) {
  let parts = html.split("<diagram>");
  parts = parts.map((value, index) => {
    if (index === 0) return value;

    const subs = value.split("</diagram>");
    const mark = markdeep.formatDiagram(unescape(subs[0]));
    return `<diagram>${mark}</diagram>${subs[1]}`;
  });

  return parts.join("");
}

export function processWithKaTex(html) {
  let parts = html.split("<mathjax>");
  parts = parts.map((value, index) => {
    if (index === 0) return value;

    const subs = value.split("</mathjax>");

    const contents = subs[0].match(/^\$(.*)\$$/)
      ? subs[0].slice(1, -1)
      : subs[0];

    const katexHtml = katex.renderToString(contents, {
      throwOnError: false,
    });

    return katexHtml + subs[1];
  });

  return parts.join("");
}

export function processWithMathJax(html) {
  let parts = html.split("<mathjax>");
  parts = parts.map((value, index) => {
    if (index === 0) return value;

    const subs = value.split("</mathjax>");

    const contents = subs[0].match(/^\$(.*)\$$/)
      ? subs[0].slice(1, -1)
      : subs[0];

    const script = `<script type="math/tex">${unescape(contents)}</script>${
      subs[1]
    }`;
    return script;
  });

  return parts.join("");
}

export function processWithYoutubeIframe(html) {
  const htmlDom = new DOMParser().parseFromString(html, "text/html");
  let player = htmlDom.getElementById("dq-player");
  if (!player) {
    player = htmlDom.querySelector("iframe.player");
  }
  let videoSource;

  if (
    player &&
    (player.src.indexOf("youtu.be") !== -1 ||
      player.src.indexOf("youtube") !== -1)
  ) {
    videoSource = player.src;
    const div = document.createElement("div");
    div.id = "dq-player";
    player.replaceWith(div);
  }

  return {
    html: htmlDom.body.innerHTML,
    videoSource,
  };
}

/**
 * Parse and processs <pre> tags in an HTML string
 * @param {string} html
 * @returns {array}
 */
export const processPreCode = html => {
  // split html string in an array
  let parts = html.split("<pre><code");

  // loop over each part
  parts = flatMap(parts, (value, index) => {
    if (index === 0) return value;

    // join the opening <pre> tag that we split above with the current value
    const fullValue = `<pre><code${value}`;

    // index of opening <code> tag
    const codeOpenStart = fullValue.indexOf("<code");
    const codeOpenEnd = fullValue.indexOf(">", codeOpenStart);
    // index of the closing </code> tag
    const codeCloseStart = fullValue.indexOf("</code", codeOpenEnd);
    // get the <code> element value
    const codeText = fullValue.substring(codeOpenEnd + 1, codeCloseStart);

    // build an HTML document that we use to access the current class / language
    const doc = new DOMParser().parseFromString(fullValue, "text/html");
    // lookup the code element in the documenent
    const code = doc.querySelector("pre code");

    // TODO: figure out why we are doing this, don't want to remove in case
    // there are edge cases that I'm unware of. -DKH
    const decodedCodeText = unescape(codeText).trim();

    // build code value object
    const codeVal = {
      code: decodedCodeText,
    };

    // get class name from <code> element
    if (code.classList !== undefined && code.classList.length > 0) {
      [codeVal.language] = code.classList; // e.g. python
    }

    const subs = value.split("</code></pre>");

    return [codeVal, subs[1]];
  });

  return parts;
};

export const processCodeTags = htmlString => {
  // Regular expression to match standalone <code> tags
  const codeTagRegex = /<code>(.*?)<\/code>/gi;
  return htmlString.replace(codeTagRegex, '<span class="code">$1</span>');
};
