// @flow
import React from "react";
import type { Node } from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
  recaptchaRef: any;
  handleOnChange: () => void;
  handleOnErrored: () => void;
  ignore?: boolean;
}

const DqReCAPTCHA = ({
  recaptchaRef,
  handleOnChange,
  handleOnErrored,
  ignore = false,
}: Props): Node => {
  /**
   * recaptcha token expired callback
   * executed when the reCAPTCHA response expires and the user needs to re-verify.
   * @param {*} e
   */
  const handleOnExpired = e => {
    console.error("recaptcha expired", e);
  };

  // short circuit if we're not in production or grecaptcha object is undefined
  if (ignore) {
    return null;
  }

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      sitekey="6LdVxp8UAAAAAMMkaj32YRBJh2ot6Ye8JENSHaaL"
      onChange={handleOnChange}
      onExpired={handleOnExpired}
      onErrored={handleOnErrored}
    />
  );
};

DqReCAPTCHA.defaultProps = {
  ignore: false,
};

export default DqReCAPTCHA;
