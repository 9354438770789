import map from "lodash/map";
import dq_fetch, { dq_graphql } from "redux/utils/dq_fetch";
import { private_actions, selectors } from "./progress_simple";

import { selectors as stepsSelectors } from "../steps_info/steps_info_simple";
import { selectors as missionSelectors } from "../lesson_info/lesson_info_simple";

export const getLessonProgress = ({ sequence }) => (dispatch, _getState) => {
  dispatch(private_actions.isUpdatingProgress());

  const graphql = `{
    progress {
      mission(sequence: ${sequence}) {
        sequence
        progress
        screens_complete
        screens_skipped
      }
    }
  }`;

  return dq_graphql(graphql).then(({ data }) => {
    dispatch(private_actions.updateLessonSteps(data.progress.mission));
  });
};

const slugOrSequence = ({ sequence, slug }) =>
  slug ? `slug: "${slug}"` : `sequence: ${sequence}`;

export const resetPathProgressLoaded = _identifier => (dispatch, _getState) => {
  dispatch(private_actions.resetPathProgressLoaded());
};
export const getPath = identifier => (dispatch, _getState) => {
  dispatch(private_actions.isUpdatingProgress());

  const graphql = `{
    path(${slugOrSequence(identifier)}) {
      sequence
      progress
      steps {
        sequence
        progress
      }
      courses {
        sequence
        progress
      }
      missions {
        sequence
        progress
        skipped
      }
    }
  }`;

  return dq_graphql(graphql).then(({ data }) => {
    dispatch(private_actions.updatePaths([data.path]));
    dispatch(private_actions.setPathProgressLoaded());
  });
};

export const getDirectory = _identifier => (dispatch, _getState) => {
  dispatch(private_actions.isUpdatingProgress());

  const graphql = `{
    paths {
      sequence
      progress
    }
  }`;

  return dq_graphql(graphql).then(({ data }) => {
    dispatch(private_actions.updatePaths(data.paths));
  });
};

export const getCourse = identifier => (dispatch, _getState) => {
  dispatch(private_actions.setRecentlyCompleted(null));
  dispatch(private_actions.isUpdatingProgress());

  const graphql = `{
    course(${slugOrSequence(identifier)}) {
      sequence
      progress
      recently_completed
      missions {
        sequence
        progress
        skipped
      }
    }
  }`;

  return dq_graphql(graphql).then(({ data }) => {
    if (data?.course) dispatch(private_actions.updateCourse(data.course));
    if (data?.course?.recently_completed) {
      dispatch(private_actions.setRecentlyCompleted(data.course.sequence));
    }
  });
};

export const markCompleteNew = (stepIndex, skipped) => (dispatch, getState) => {
  const screenKey = stepsSelectors.steps_info(getState())[stepIndex].index;
  const { type } = stepsSelectors.steps_info(getState())[stepIndex];
  const { sequence } = missionSelectors.lessonInfo(getState());
  const graphql = `mutation {
    mark_complete (mission_sequence: ${sequence}, screen_key: "${screenKey}", skipped: ${skipped}) {
      success
    }
  }`;
  const allCompleted = selectors
    .lessonStepsCompleted(getState())
    .reduce((r, c, index) => {
      if (index === stepIndex) return r; // && true
      return r && c;
    }, true);
  dispatch(
    private_actions.completeLessonStep({
      step_index: stepIndex,
      screen_id: screenKey,
      type,
      all_completed: allCompleted,
    }),
  );
  if (skipped)
    dispatch(
      private_actions.skipLessonStep({
        step_index: stepIndex,
        screen_id: screenKey,
        type,
        all_completed: allCompleted,
      }),
    );

  return dq_graphql(graphql);
};

const markCompleteOld = stepIndex => (dispatch, getState) => {
  const missionId = missionSelectors.lessonInfo(getState()).id;
  const screenId = stepsSelectors.steps_info(getState())[stepIndex].id;
  const { type } = stepsSelectors.steps_info(getState())[stepIndex];

  const url = `/api/v2/missions/missions/${missionId}/screens/${screenId}/complete/`;
  return dq_fetch(url, { post_obj: {} })
    .then(data => {
      const completes = map(data.screens, "complete");
      const justCompleted =
        selectors.lessonStepsCompleted(getState())[stepIndex] !==
        completes[stepIndex];
      const skipped = selectors.lessonStepsSkipped(getState())[stepIndex];
      const allCompleted = completes.reduce((r, c) => r && c, true);

      if (justCompleted || (skipped && !data.screens[stepIndex]?.skipped)) {
        dispatch(
          private_actions.completeLessonStep({
            step_index: stepIndex,
            screen_id: screenId,
            type,
            all_completed: allCompleted,
          }),
        );
      }
    })
    .catch(console.warn);
};

export const markComplete = stepIndex => (dispatch, getState) => {
  const alreadyCompleted = selectors.lessonStepsCompleted(getState())[
    stepIndex
  ];
  const skipped = selectors.lessonStepsSkipped(getState())[stepIndex];
  if (alreadyCompleted && !skipped) return Promise.resolve();

  const stepInfo = stepsSelectors.steps_info(getState())[stepIndex];
  if (!stepInfo) return Promise.resolve();

  if (stepInfo?.new_system)
    return markCompleteNew(stepIndex, false)(dispatch, getState);
  return markCompleteOld(stepIndex)(dispatch, getState);
};
