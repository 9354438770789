import Immutable from "seamless-immutable";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";
import defaultTo from "lodash/defaultTo";
import map from "lodash/map";
import padStart from "lodash/padStart";
import reverse from "lodash/reverse";
import take from "lodash/take";
import { LESSON_RESET_ACTION_NAME } from "../lesson_info/lesson_info_simple";

// -------
// Initial State
// --------

const initial_state = {
  progress_over_time: {},
  path_progress_loaded: false,
  updating_progress: false,
  paths_progress: {},
  path_steps_progress: {},
  courses_progress: {},
  lessonsProgress: {},
  lessonsSkipped: {},
  lessonStepsCompleted: [],
  lessonStepsSkipped: [],
  recentlyCompleted: null,
};

// -------
// Selectors
// --------
const BASE = "progress";
export { BASE as BASE_SELECTOR_PATH };

const simpleSelectors = make_simple_selectors(initial_state, BASE);

export const selectors = {
  ...simpleSelectors,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "progress/";

const other_handlers = {};
other_handlers[LESSON_RESET_ACTION_NAME] = state =>
  state.merge({
    lessonStepsCompleted: initial_state.lessonStepsCompleted,
    lessonStepsSkipped: initial_state.lessonStepsSkipped,
  });

const public_handlers = {
  reset: () => Immutable(initial_state),
};

const progressBySequence = list => {
  if (!list) {
    return {};
  }

  const retval = list.reduce((result, nextResult) => {
    if (nextResult?.sequence)
      return {
        ...result,
        [`${nextResult.sequence}`]: defaultTo(nextResult.progress, 0),
      };
    return result;
  }, {});

  return retval;
};

const skippedBySequence = list => {
  if (!list) {
    return {};
  }

  const retval = list.reduce((result, nextResult) => {
    if (nextResult?.sequence)
      return {
        ...result,
        [`${nextResult.sequence}`]: defaultTo(nextResult.skipped, false),
      };
    return result;
  }, {});

  return retval;
};

const pad = number => padStart(number, 2, "0");
const daysOfHistory = 180;

const private_handlers = {
  setPathProgressLoaded: state => state.merge({ path_progress_loaded: true }),
  resetPathProgressLoaded: state =>
    state.merge({ path_progress_loaded: false }),
  updateProgressOverTime: (state, { payload }) =>
    state.merge({
      progress_over_time: {
        ...state.progress_over_time,
        [payload.sequence]: reverse(
          take(
            map(payload.progress_over_time, ({ date, percentage }) => {
              const dateObj = new Date(date);
              return {
                date: `${pad(dateObj.getMonth() + 1)}/${pad(
                  dateObj.getDate() + 1,
                )}`,
                percentage,
              };
            }),
            daysOfHistory,
          ),
        ),
      },
    }),
  isUpdatingProgress: state => state.merge({ updating_progress: true }),
  updatePaths: (state, { payload = [] }) => {
    let newState = state.merge({ updating_progress: false });
    payload.forEach(path => {
      // eslint-disable-next-line no-param-reassign
      newState = newState.merge(
        {
          paths_progress: progressBySequence([path]),
          path_steps_progress: progressBySequence(path.steps),
          courses_progress: progressBySequence(path.courses),
          lessonsProgress: progressBySequence(path.missions),
          lessonsSkipped: skippedBySequence(path.missions),
        },
        { deep: true },
      );
    });
    return newState;
  },
  updateCourse: (state, { payload = {} }) =>
    state.merge(
      {
        courses_progress: progressBySequence([payload]),
        lessonsProgress: progressBySequence(payload.missions),
        lessonsSkipped: skippedBySequence(payload.missions),
        updating_progress: false,
      },
      { deep: true },
    ),
  updateLessonSteps: (state, { payload }) => {
    if (payload == null) {
      return state.merge({
        lessonStepsCompleted: initial_state.lessonStepsCompleted,
        lessonStepsSkipped: initial_state.lessonStepsSkipped,
        updating_progress: false,
      });
    }
    return state.merge({
      lessonStepsCompleted: payload.screens_complete,
      lessonStepsSkipped: payload.screens_skipped,
      lessonsProgress: state.lessonsProgress.merge(
        progressBySequence([payload]),
      ),
      updating_progress: false,
    });
  },
  setRecentlyCompleted: (state, { payload }) =>
    state.merge({ recentlyCompleted: payload }),
  completeLessonStep: (state, { payload: { step_index } }) =>
    state
      .setIn(["lessonStepsCompleted", step_index], true)
      .setIn(["lessonStepsSkipped", step_index], false),
  skipLessonStep: (state, { payload: { step_index } }) =>
    state.setIn(["lessonStepsSkipped", step_index], true),
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  other_handlers,
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
