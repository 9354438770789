const NOTIFICATION_TIMEOUT = 5000;
const ACTION_TIMEOUT = 1000;
const HIGHLIGHT_TIMEOUT = 3000;
const UNAUTHORIZED_USER_REDIRECT_URL = "/login";
const MARKETING_SITE_URL = "https://www.dataquest.io";
const MARKETING_SITE_CATALOG_URL = "https://www.dataquest.io/directory";
const MARKETING_CATALOG_PATH_URL = "https://www.dataquest.io/path/";
const MARKETING_CATALOG_CAREER_PATHS_URL =
  "https://www.dataquest.io/data-science-courses/career-paths/";
const MARKETING_CATALOG_SKILL_PATHS_URL =
  "https://www.dataquest.io/data-science-courses/skill-paths/";
const MARKETING_CATALOG_COURSES_URL =
  "https://www.dataquest.io/data-science-courses/individual-courses/";
const MARKETING_TEAMS_PAYMENT =
  "https://www.dataquest.io/for-business/#subscribe";
const MEMBERS_TYPE = "Members";
const INVITES_TYPE = "Invites";
const MEMBER_ROLE = "member";
const ADMIN_ROLE = "admin";
const OWNER_ROLE = "owner";

const AI_REQUEST_EXPLANATION = "explanation";
const AI_REQUEST_EXPLANATION_CORRECT = "correct_explanation";
const AI_REQUEST_EXPLANATION_INCORRECT = "incorrect_explanation";
const AI_REQUEST_EXPLANATION_ERROR = "error_explanation";
const AI_REQUEST_CHAT = "chat";

const PROFILE_INFO_EDIT_FIELDS = [
  "fullname",
  "email",
  "location",
  "githubUrl",
  "linkedinUrl",
  "websiteUrl",
  "isPublic",
  "workStatus",
  "universityName",
  "jobTitle",
  "companyName",
  "timezone",
];

const PROFILE_INFO_FIELDS = PROFILE_INFO_EDIT_FIELDS.concat(
  "isSubscribed",
  "imageUrl",
  "dateJoined",
);

const REDIRECT_AFTER_LOGOUT_URL =
  __DEV__ || __STAGE__ ? "/login" : MARKETING_SITE_URL;

// onboarding
const ONBOARDING_SHOWN_BUT_NOT_COMPLETED = "onboarding_shown_but_not_completed";

// code runs
const MAX_CONNECT_RETRIES = 3;
const CONNECT_RETRY_DELAY = 3 * ACTION_TIMEOUT; // ms
const CONNECTED_STATUS_READY = "ready";
const CONNECTED_STATUS_STARTING = "starting";
const CODE_STATUS_PENDING = "pending";

// subscription
const FINANCIAL_AID_URL =
  "https://webforms.pipedrive.com/f/1AhG7dEwEN55zcIOHcoSvrSWL2kmXdJ5AJMotF6bdO2QlmBqca9Qrp8dYxRivPF87";
const TEAM_SUBSCRIPTION_URL =
  "https://www.dataquest.io/for-business/#subscribe";
const DEFAULT_CURRENCY = "USD";
const SUBSCRIPTION_PERIOD_LIFETIME = "lifetime";
const SUBSCRIPTION_PERIOD_BIENNIAL = "biennial";
const SUBSCRIPTION_PERIOD_ANNUAL = "annual";
const SUBSCRIPTION_PERIOD_QUARTERLY = "quarterly";
const SUBSCRIPTION_PERIOD_MONTHLY = "monthly";
const PLAN_INTERVAL_MONTHLY = SUBSCRIPTION_PERIOD_MONTHLY;
const PLAN_INTERVAL_ANNUAL = "yearly";
const SUBSCRIPTION_TYPE_BASIC = "basic";
const SUBSCRIPTION_TYPE_PREMIUM = "premium";
const SUBSCRIPTION_TYPE_FUNDAMENTALS = "fundamentals";
const SUBSCRIPTION_TYPE_FREE = "free";
const MARKETING_CAMPAIGNS_MAP = {
  [SUBSCRIPTION_PERIOD_MONTHLY]: 4317169,
  [SUBSCRIPTION_PERIOD_ANNUAL]: 10808034,
  [SUBSCRIPTION_PERIOD_LIFETIME]: 10808042,
};
const SUBSCRIPTION_DEFAULT_PLAN = SUBSCRIPTION_TYPE_PREMIUM;
const STRIPE_PAYMENT_TYPE = "stripe";
const PAYPAL_PAYMENT_TYPE = "braintree";
const AMAZONPAY_PAYMENT_TYPE = "amazon_payments";
const WALLET_PAYMENT_TYPE = "wallet";
const APPLEPAY_PAYMENT_TYPE = "apple_pay";
const GOOGLEPAY_PAYMENT_TYPE = "google_pay";

// dashboard
const PATH_TYPE_CAREER = "career";
const PATH_TYPE_SKILL = "skill";
const LEARNING_PATH_SLUG_KEY = "dq-current-learning-path";
const LEARNING_PATH_VERSION_KEY = "dq-current-learning-path-version";
const DEFAULT_PATH_END_URL = "/learning/path";

const PROJECTS_URL = "/projects";
const DASHBOARD_URL = "/dashboard";

const LEVEL_NAMES = ["Beginner", "Intermediate", "Advanced"];
const INSTALLATION_PROJECT = "installation_project";
const PORTFOLIO_PROJECT = "portfolio_project";

const BETA_PATH_SEQUENCES = [];
const BETA_COURSE_SEQUENCES = [];
const BETA_PATH_PART_SLUGS = [];

const PROJECTS_COURSE_SEQUENCES = [93];
const WELCOME_KIT_STEPS = {
  mission_complete: {
    popoverOpts: {
      text: "You can complete any of the lessons from your learning path.",
      attachTo: {
        element: "#DashboardViewPath",
        on: "right-start",
      },
    },
  },
  exercise_complete: {
    popoverOpts: {
      text: "You can complete your first exercise once you start a lesson.",
      attachTo: {
        element: "#DashboardHeaderLearnCTA",
        on: "left-start",
      },
    },
  },
  directory_view: {
    url: "/catalog",
  },
  community_join: {
    popoverOpts: {
      text:
        "You can see here the latest posts on the community, introduce yourself by commenting or starting a new post.",
      attachTo: {
        element: "#dashboardCommunityCard",
        on: "left-start",
      },
    },
  },
  problem_complete: {
    popoverOpts: {
      text:
        "Become an expert by practicing your skills with problem set tailored by our team of data scientist.",
      attachTo: {
        element: "#dashboardNavPractice",
        on: "right-start",
      },
      scrollTo: false,
    },
  },
  profile_complete: {
    popoverOpts: {
      text: "Claim 3 days of premium access by completing your profile.",
      attachTo: {
        element: "#dashboardHeaderProfileCTA",
        on: "right-start",
      },
      scrollTo: false,
    },
  },
  project_start: {
    popoverOpts: {
      text:
        "Use your newly acquired skills to complete a guided project for your portfolio.",
      attachTo: {
        element: ".dashboardLessonTypeProject",
        on: "right-start",
      },
    },
  },
  article_step: {
    url: "https://support.dataquest.io/en/articles/92-where-can-i-get-help",
  },
  survey_step: {
    url: "https://dataquestio.typeform.com/to/Oy1QbLZv",
  },
  referral_step: {
    popoverOpts: {
      text:
        "Share your referral link here via social media, or send an invite via email.",
      attachTo: {
        element: "#navigationReferralCTA",
        on: "left-start",
      },
    },
  },
  select_path: {
    url: "https://www.dataquest.io/directory",
  },
  course_complete: {
    popoverOpts: {
      text: "You can complete any of the courses from your learning path.",
      attachTo: {
        element: "#DashboardViewPath",
        on: "right-start",
      },
    },
  },
  subscribe_step: {
    url: "/payment",
  },
};

export {
  NOTIFICATION_TIMEOUT,
  ACTION_TIMEOUT,
  HIGHLIGHT_TIMEOUT,
  MAX_CONNECT_RETRIES,
  CONNECT_RETRY_DELAY,
  CONNECTED_STATUS_READY,
  CONNECTED_STATUS_STARTING,
  CODE_STATUS_PENDING,
  DEFAULT_CURRENCY,
  SUBSCRIPTION_PERIOD_LIFETIME,
  SUBSCRIPTION_PERIOD_BIENNIAL,
  SUBSCRIPTION_PERIOD_ANNUAL,
  SUBSCRIPTION_PERIOD_QUARTERLY,
  SUBSCRIPTION_PERIOD_MONTHLY,
  SUBSCRIPTION_TYPE_BASIC,
  SUBSCRIPTION_TYPE_FREE,
  SUBSCRIPTION_TYPE_PREMIUM,
  SUBSCRIPTION_TYPE_FUNDAMENTALS,
  SUBSCRIPTION_DEFAULT_PLAN,
  PLAN_INTERVAL_MONTHLY,
  PLAN_INTERVAL_ANNUAL,
  STRIPE_PAYMENT_TYPE,
  PAYPAL_PAYMENT_TYPE,
  AMAZONPAY_PAYMENT_TYPE,
  WALLET_PAYMENT_TYPE,
  APPLEPAY_PAYMENT_TYPE,
  GOOGLEPAY_PAYMENT_TYPE,
  UNAUTHORIZED_USER_REDIRECT_URL,
  REDIRECT_AFTER_LOGOUT_URL,
  MARKETING_SITE_URL,
  MARKETING_SITE_CATALOG_URL,
  MARKETING_CATALOG_CAREER_PATHS_URL,
  MARKETING_CATALOG_SKILL_PATHS_URL,
  MARKETING_CATALOG_COURSES_URL,
  MARKETING_CATALOG_PATH_URL,
  MARKETING_TEAMS_PAYMENT,
  MARKETING_CAMPAIGNS_MAP,
  ONBOARDING_SHOWN_BUT_NOT_COMPLETED,
  PATH_TYPE_CAREER,
  PATH_TYPE_SKILL,
  LEVEL_NAMES,
  INSTALLATION_PROJECT,
  PORTFOLIO_PROJECT,
  LEARNING_PATH_SLUG_KEY,
  LEARNING_PATH_VERSION_KEY,
  DEFAULT_PATH_END_URL,
  PROJECTS_URL,
  DASHBOARD_URL,
  BETA_PATH_SEQUENCES,
  BETA_PATH_PART_SLUGS,
  BETA_COURSE_SEQUENCES,
  PROJECTS_COURSE_SEQUENCES,
  WELCOME_KIT_STEPS,
  FINANCIAL_AID_URL,
  TEAM_SUBSCRIPTION_URL,
  MEMBERS_TYPE,
  INVITES_TYPE,
  MEMBER_ROLE,
  ADMIN_ROLE,
  OWNER_ROLE,
  PROFILE_INFO_EDIT_FIELDS,
  PROFILE_INFO_FIELDS,
  AI_REQUEST_EXPLANATION,
  AI_REQUEST_EXPLANATION_CORRECT,
  AI_REQUEST_EXPLANATION_INCORRECT,
  AI_REQUEST_EXPLANATION_ERROR,
  AI_REQUEST_CHAT,
};
