// @flow
import Immutable from "seamless-immutable";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";

// -------
// Initial State
// --------
type func = State => any;
export type State = {
  +loaded: boolean,
  +paymentInProgress: boolean,
  +payment_type: string,
  +payment_success: boolean,
  +payment_checking: boolean,
  +subscription_cancelled: ?boolean,
  +subscription_changes_scheduled: ?boolean,
  +subscription_period_end: ?Date,
  +subscriptionPauseEnd: ?Date,
  +subscriptionId: ?string,
  +hasAnySubscription: ?boolean,

  +stripe_key: ?string,
  +cc_using: string,

  +google_pay_merchant_id: ?string,
  +braintree_key: ?string,
  +amazon_key: ?string,
  +amazon_id: ?string,
  +referralDiscount: null,
  +selectedCurrency: ?string,
};
type SelectorsState = {
  +loaded: func,
  +paymentInProgress: func,
  +payment_type: func,
  +payment_success: func,
  +payment_checking: func,
  +subscription_cancelled: func,
  +subscription_period_end: func,

  +stripe_key: func,
  +cc_using: func,

  +google_pay_merchant_id: func,
  +braintree_key: func,
  +amazon_key: func,
  +amazon_id: func,
  +referralDiscount: func,
  +selectedCurrency: func,
};

type ReducerState = {
  reducer: State,
  private_actions: any,
  actions: any,
  ACTION_TYPES: { +type: string },
};

const initial_state: State = {
  loaded: false,
  paymentInProgress: false,
  payment_type: "none",
  payment_success: false,
  payment_checking: false,
  subscription_cancelled: null,
  subscription_changes_scheduled: null,
  subscription_period_end: null,
  subscriptionPauseEnd: null,
  subscriptionId: "",
  hasAnySubscription: null,

  stripe_key: "",
  cc_using: "---- ---- ---- ----",

  google_pay_merchant_id: "",
  braintree_key: null,
  amazon_key: null,
  amazon_id: null,
  referralDiscount: null,
  selectedCurrency: null,
};

// -------
// Selectors
// --------
const BASE = "payment_info";
export { BASE as BASE_SELECTOR_PATH };

const simpleSelectors = (make_simple_selectors(
  initial_state,
  BASE,
): SelectorsState);

export const selectors = {
  ...simpleSelectors,
};

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "payment_info/";

const public_handlers = {
  reset: () => (Immutable(initial_state): State),
  setChecking: (state, { payload = true }) =>
    state.merge({
      paymentInProgress: payload,
      payment_checking: payload,
      payment_success: false,
    }),
  setSuccess: (state, { payload }) => {
    const results = state.merge({
      paymentInProgress: false,
      payment_checking: false,
      subscriptionId:
        payload && payload.subscription_id ? payload.subscription_id : "",
      payment_success: true,
    });

    return results;
  },
};

const private_handlers = {
  setInfo: (state, { payload }) =>
    state.merge({
      loaded: true,
      payment_type: payload.payment_type || "none",
      payment_checking: false,
      payment_success: payload.payment_success || false,
      subscriptionId: payload.subscription_id || "",
      subscription_cancelled: payload.subscription_cancelled,
      subscription_changes_scheduled: payload.subscription_changes_scheduled,
      subscription_period_end: payload.subscription_period_end
        ? new Date(payload.subscription_period_end)
        : null,
      subscriptionPauseEnd: payload.subscription_pause_end
        ? new Date(payload.subscription_pause_end)
        : null,
      stripe_key: payload.stripe_key,
      cc_using: payload.cc_using || initial_state.cc_using,
      braintree_key: payload.braintree_key,
      google_pay_merchant_id: payload.google_pay_merchant_id,
      amazon_key: payload.amazon_key,
      amazon_id: payload.amazon_id,
      hasAnySubscription: payload.has_any_subscription,
      selectedCurrency: payload.selected_currency,
    }),
  setReferralDiscount: (state, { payload }) => {
    const discount = payload.referral_discount;
    if (discount) {
      return state.merge({
        referralDiscount: {
          subscription_period: discount.subscription_period
            ? discount.subscription_period.toLowerCase()
            : null,
          amount_off:
            discount.type.toLowerCase() === "discount_amount"
              ? discount.amount
              : null,
          percent_off:
            discount.type.toLowerCase() === "discount_percent"
              ? discount.amount
              : null,
        },
      });
    }
    return state;
  },
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = (make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
}): ReducerState);
export default reducer;
